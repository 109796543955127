import React from "react";
import { useEffect } from "react";
import { scroller } from "react-scroll";
import "../assets/css/home.css";
import "../assets/css/global_styles.css";
import LandingComponent from "./LandingComponent";
import FeaturesComponent from "./FeaturesComponent";
import WatchVideoComponent from "./WatchVideoComponent";
import CommitmentStardardComponent from "./CommitmentStandardComponent";
import ComparisionMatrixComponent from "./ComparisionMatrixComponent";
import FeaturedInComponent from "./FeaturedInComponent";
import GalleryComponent from "./GalleryComponent";
// import TestimonialComponent from "./TestimonialComponent";
import BookYourAurmSafe from "./BookYourAurmSafe";
import FaqsComponent from "./FaqsComponent";
import FooterComponent from "./FooterComponent";

/**
  Combines all the components of the website into a single page
*/
function HomeComponent() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
        const sectionId = hash.substring(1);
        const section = document.getElementById(sectionId);
        if (section) {
          setTimeout(() => {
              section.scrollIntoView({ behavior: 'smooth' });
          }, 1000);
      }
    }
}, []);

  const scrollToSection = (sectionName) => {
    const scroller_section = document.getElementById(sectionName);
    if (scroller_section) {
      scroller.scrollTo(sectionName, {
        duration: 50,
        smooth: true,
      });
    }
    return true;
  };

  useEffect(() => {
    setTimeout(() => {
      const section_element = localStorage.getItem("scrollToSection");
      if (section_element) {
        const isscrolled = scrollToSection(section_element);
        if (isscrolled) {
          localStorage.removeItem("scrollToSection");
        }
      }
    }, 1000);

  }, []);
  return (
    <div id="home_page">
      <LandingComponent />
      <FeaturesComponent />
      <WatchVideoComponent videoBg={"video_section_main"} homePage={true}/>
      <CommitmentStardardComponent/>  
      <ComparisionMatrixComponent />
      <FeaturedInComponent />
      <GalleryComponent/>
      <BookYourAurmSafe/>
      <FaqsComponent />
      <FooterComponent />
    </div>
  );
}

export default HomeComponent;
