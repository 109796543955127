
import React, { useEffect, useState} from "react";
import HeaderComponent from "./HeaderComponent";
import "../assets/css/landing.css";
import dot from "../assets/images/dot.svg";
import video_mask from "../assets/images/landing_video_mask_bottom.png";
const react_app_site_full_name = process.env.REACT_APP_SITE_FULLNAME;
const bgImageUrl = process.env.REACT_APP_LANDING_SECTION_URL;

/**
 * Shows the Landing Page
 */
function LandingComponent() {
  useEffect(() => {
    if(bgImageUrl){
      document.documentElement.style.setProperty('--bgImageUrl', `url('${bgImageUrl}')`);
    }
  }, []);
  const [remainingTime, setRemainingTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const targetDateString = process.env.REACT_APP_TARGET_DATE || NaN;
  const [islaunched, setLaunched] = useState(false);
  const [isComingSoon, setComingSoon] = useState(false);
  let targetDate
  if (targetDateString) {
    const parsedDate = new Date(targetDateString);  
    if (!isNaN(parsedDate.getTime())) {
      targetDate = parsedDate.getTime();
    } else {
      console.warn("Invalid target date format:", targetDateString);
      targetDate = null;
    }
  } else {
    console.warn("Target date not provided.");
    targetDate = null;
  }
  useEffect(() => {
    function updateCountdown() {
      const now = new Date().getTime();
      var hours= 0;
      var minutes= 0;
      var seconds= 0;
      if(targetDate){
        const difference = targetDate - now;
        if (difference <= 0) {
          setLaunched(true);
          setComingSoon(false);
          return { days: 0, hours: 0, minutes: 0, seconds: 0};
        }
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        seconds = Math.floor((difference / 1000) % 60);
        minutes = Math.floor((difference / (1000 * 60)) % 60);
        hours= Math.floor((difference / (1000 * 60 * 60)));
        setRemainingTime({ days, hours, minutes, seconds });
      }
      else if(!targetDate){
        console.log("target date not provided");
        setRemainingTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        setComingSoon(false);
        setLaunched(false);
      }
      if ((targetDate && hours < 72) || (targetDate && hours === 72 && minutes === 0 && seconds === 0)) {
        console.log("coming soon");
        setComingSoon(true);
        setLaunched(false);
      }
    }  
    updateCountdown();  
    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, [targetDate]);
  return (
    <div id="landing_section">
      <HeaderComponent />
      <div id="lading_section_for_mobile_web" className={`${islaunched ? 'lading_section_for_mobile_web' : isComingSoon ? 'lading_section_for_mobile_web' : 'lading_section_for_mobile_web_coming'}`}>
      <div id="banner_section">
        <div className="container landing_title_section">
          <div className="landing_title text-shadow">Presenting Aurm for</div>
            <span className="app_site_name landing_title text-shadow">{react_app_site_full_name}.</span>
          <div className="landing_title">Safe Deposit Vaults, Re-imagined.</div>
          <div className="landing_subtitle">Bringing the future of secure storage to you</div>
        </div>
      </div>
      <div className="image-container">
    <img src={video_mask} alt="Centered_mask" id="landing-bottom-centered-image" className={`${!isComingSoon && !islaunched ? 'landing-bottom-centered-image_cominglanding-bottom-centered-image' : 'landing-bottom-centered-image'}`} />
    <div className={`launching_in ${islaunched && 'launched_div_styles'} text-center text-white mt-2`}>
    {!islaunched && !isComingSoon && (
  <span className="launch_timer launch_timer_coming_soon">Coming Soon</span>
  )}
  {isComingSoon && (
    <>
      <span className="launch_text">Launching in</span>
      <div className="launch_timer" id="countdown">
            <span className="hours launch_timer_width">{remainingTime.hours}</span>
            <span className="separator">:</span>
            <span className="minutes launch_timer_width">{remainingTime.minutes}</span>
            <span className="separator">:</span>
            <span className="seconds launch_timer_width">{remainingTime.seconds}</span>
      </div>
      <div className="landing_labels">
            <span className="landing_label">hrs</span>
            <span className="separator">&nbsp;</span>
            <span className="landing_label landing_label_mints">mins</span>
            <span className="separator">&nbsp;</span>
            <span className="landing_label">secs</span>
      </div>
      </>
  )}
  {islaunched && (
    <>
    <span className="launch_text launch_text_live">
      We are now <span className="app_site_name">LIVE</span>
    </span>
  
      <div className="container d-flex justify-content-center touch_btn_div position-absolute bg-transparent">
          <img src={dot} alt="dot" />
          <div>
            <a href="https://api.whatsapp.com/send/?phone=919740741016&text=I'm intrigued about Aurm. Tell me more! &type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
              <button
                className="btn btn-primary rounded_pink_btn pt-2 pb-2"
                id="get_in_touch_btn"
              >
                Get in touch
              </button>
            </a>
          </div>
          <img src={dot} alt="dot" />
        </div>
        </>
      )}
    </div>
    </div>
    </div>
    </div>
  );
}

export default LandingComponent;